import React, { useState, useEffect } from 'react'
import Loader from '../components/Loader'
import axios from 'axios'
import './Main.css'
import DashboardCard from './DashboardCard'
import Footer from './Footer'
import { useSelector } from 'react-redux'

const Main = () => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState()
  const userLogin = useSelector((state) => state.userLogin)
  const {userCred} = userLogin
//  console.log(userCred)
 const email = userCred.email
 const token = userCred.token
 const schoolName = userCred.value
  useEffect(() => {
    // await axios.get('/api/students/:classes')
    const fetchItems = async () => {
      setLoading(true)
      // console.log('Debug: loading')
      // console.log(token)
      const { data } = await axios.post('/dashboard', {email, token, schoolName})
      setLoading(false)
      //console.log(data)
      setItems(data)
    }
    fetchItems()
  }, [])

  return (
    <main>
      <div className='main__container'>
        {loading ? (
          <Loader />
        ) : (
          <div className='card-handler'>
            {/* {loading? <Loader/
   {}>} */}
            {/* {console.log('dfsdf')} */}
            {/* {loading ? <Loader/>: } */}
            {items.map((item) => (
              // <div key={item._id}>
              <DashboardCard
                key={item?._id}
                takeme={item?.takeme}
                title={item?.title}
                number={item?.number}
                image={item?.image}
              />
              // </div>
            ))}
          </div>
        )}
      </div>

      {!loading && <Footer />}
    </main>
  )
}

export default Main
