export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST'
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS'
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL'
export const STUDENT_LIST_CLEAR = 'STUDENT_LIST_CLEAR'

//following is for displaying students belonging to the particular classes

export const STUDENT_CLASS_LIST_REQUEST = 'STUDENT_CLASS_LIST_REQUEST'
export const STUDENT_CLASS_LIST_SUCCESS = 'STUDENT_CLASS_LIST_SUCCESS'
export const STUDENT_CLASS_LIST_FAIL = 'STUDENT_CLASS_LIST_FAIL'
export const STUDENT_CLASS_LIST_CLEAR = 'STUDENT_CLASS_LIST_CLEAR'

//followig is for displaying the information of the student for paying fees.
export const STUDENT_SEARCH_REQUEST = 'STUDENT_SEARCH_REQUEST'
export const STUDENT_SEARCH_SUCCESS = 'STUDENT_SEARCH_SUCCESS'
export const STUDENT_SEARCH_FAIL = 'STUDENT_SEARCH_FAIL'
export const STUDENT_SEARCH_CLEAR = 'STUDENT_SEARCH_CLEAR'

//following is for registering the student

export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST'
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS'
export const STUDENT_REGISTER_FAIL = 'STUDENT_REGISTER_FAIL'
//STUDENT DELETE
export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST'
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS'
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL'

//following is for student attendance
export const STUDENT_ATTENDANCE_REQUEST = 'STUDENT_ATTENDANCE_REQUEST'
export const STUDENT_ATTENDANCE_SUCCESS = 'STUDENT_ATTENDANCE_SUCCESS'
export const STUDENT_ATTENDANCE_FAIL = 'STUDENT_ATTENDANCE_FAIL'
export const STUDENT_ATTENDANCE_RESET = 'STUDENT_ATTENDANCE_RESET'

//student fees

export const STUDENT_FEES_REQUEST = 'STUDENT_FEES_REQUEST'
export const STUDENT_FEES_SUCCESS = 'STUDENT_FEES_SUCCESS'
export const STUDENT_FEES_FAIL = 'STUDENT_FEES_FAIL'
export const STUDENT_FEES_RESET = 'STUDENT_FEES_RESET'
