import React from 'react'
const NotFound = () => {
  return (
    <div className='notFound'>
      <img src='./images/notFound.webp' alt='' />
    </div>
  )
}

export default NotFound
