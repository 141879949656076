const Classes = [
  {
    _id: 1,
    classname: 'Nursery',
  },
  {
    _id: 2,
    classname: 'LKG',
  },
  {
    _id: 3,
    classname: 'UKG',
  },
  {
    _id: 4,
    classname: 'One',
  },
  {
    _id: 5,
    classname: 'Two',
  },
  {
    _id: 6,
    classname: 'Three',
  },
  {
    _id: 7,
    classname: 'Four',
  },

  {
    _id: 8,
    classname: 'Five',
  },
  {
    _id: 9,
    classname: 'Six',
  },
  {
    _id: 10,
    classname: 'Seven',
  },
  {
    _id: 11,
    classname: 'Eight',
  },
  {
    _id: 12,
    classname: 'Nine',
  },
  {
    _id: 13,
    classname: 'Ten',
  },
]

export default Classes
